export const css = {
  themeColor: "#223947",
  textColor: "#FFFFFF",
  themeColor2: "#4d6c80",
  lightColor: "#b4c9d6",

  pending: "#FCF2D5",
  green: "#80bdb2",
  red: "#FFE7E8",
  headericonSize: "1.5rem",
  iconSize1: "1rem",
  iconSize2: "1.1rem",
  Request: "#ECE9FE",
  Requested: "#b0a6ed",
  Booked: "#C5F6E2",
  Booked1: "#76f5c1",
  Preadmission: "#d8EFFF",
  Preadmission1: "#6dbef7",
  Preadmission2: "#EAF4FF",
  Collected: "#D1B3FF",
  Admitted: "#FFFFFF",
  Pending: "#FF9688",
  Complete: "#80bdb2",
  thickBlue: "#03469E",
};

export const baseURL = process.env.REACT_APP_API_URL;
export const basefileURL = process.env.REACT_APP_API_ASSETS_URL;
export const chatUrl = process.env.REACT_APP_CHAT_URL;
export const chartsPort = process.env.REACT_APP_CHARTS_PORT;

export const jitsiMeetUrl = "https://jitsi.tribandtech.com:8443/";
export const googleMapKey = "AIzaSyB8hjaTbAN8rRehBEvtjT8OO0hBY5_2-C4";
export const AIURL = "https://baithnabot.tribandtech.com:2054/";
export const QUBOTO = "https://baithnabot.tribandtech.com:4567/";
export const SCUrl = "https://baithna.tribandtech.com:7075/";

export const encryptKey = "1M3D^ERZ@B@!TN@1"
export const ivKey = "1B@!TN@@M3D^ERZ1"

export const urls = {
  ICD_AM_10: "ICD_AM_10",
  SymptomCheck: {
    getNames: "get_unique_names",
    getQuestions: "get_Questions",
    getAnswers: "get_answers"
  },
  service: {
    save: "Service/Save",
    getById: "Service/GetById",
    getAll: "Service/GetAll",
    getAllWithoutToken: "Service/GetAllWithoutToken",
    uploadImage: "Service/AddServiceImage"
  },
  portfolio: {
    save: "Portfolio/Save",
    getById: "Portfolio/GetById",
    getAll: "Portfolio/GetAll",
    getAllWithoutToken: "Portfolio/GetAllWithoutToken",
    uploadImage: "Portfolio/AddPortfolioImage"
  },
  auth: {
    login: "Authentication/Login",
    updatePassword: "Authentication/UpdatePassword",
    forgotPassword: "Authentication/SendForgotPasswordMail",
    createPassword: "Authentication/SetPassword",
    createPatient: "User/RegisterPatient",
  },
  user: {
    save: "User/Save",
    getAll: "User/GetAll",
    getById: "User/GetById",
    updateStatus: "User/UpdateUserStatus",
    getAllPatients: "User/GetAllPatient",
    getAllClinicinas: "User/GetAllPhysician",
    patientGetById: "User/GetPatientById",
    savePatient: "User/PatientSave",
    getCollectionAgent: "User/GetCollectionAgent",
    getLabTechnicians: "User/GetLabTechnicians",
    getDetailsById: "User/GetDetailsById",
    getPhysicianDetails: "User/GetPhysicianDetails",
    getAllHospitalAdmin: "User/GetAllHospitalAdmin",
    addProfile: "User/AddProfilePhoto",
    uploadDoc: "User/UploadWhatsappDocs",
    getProfile: "User/GetUserProfile",
    updateProfile: "User/UpdateUserProfile",
    getResources: "User/GetResources",
    getEnquiryUser: "User/GetEnquiryUser",
    saveEnquiryUser: "User/SaveEnquiryUser",
  },
  Ambulance: {
    Save: "Ambulance/Save",
    GetAll: "Ambulance/GetAll",
    GetById: "Ambulance/GetById",
    SaveUploads: "Ambulance/SaveUploads",
  },
  driver: {
    saveDiver: "Driver/Save",
    getAllDrivers: "Driver/GetAll",
  },
  patient: {
    getById: "Patient/GetAllPatientinfo",
    patient: "Patient/InsertPatient",
    savePatientVitals: "Patient/SavePatientVitals",
    getPatientVitals: "Patient/GetPatientVitals",
    getPatientEducation: "Patient/GetPatientEducation",
    savePatientEducation: "Patient/SavePatientEducation",
    deletePatientEducation: "Appointment/DeletePatientEducation",

    savePatientNote: "Patient/SavePatientNote",
    getAllPatientNotes: "Patient/GetAllPatientNote"

    // GetAllPatientinfo: "Patient/GetAllPatientinfo",
    // getAllInsurance: "Patient/GetAllInsuranceCompany",
    // getInsuranceContract: "Patient/GetAllInsuranceContract",

    // savePatientEducation: "Patient/SavePatientEducation",
    // getPatientEducation: "Patient/GetPatientEducation",

    // getAllDetailsForLongitudal: "Patient/GetPatientDetailsById",
    // saveRelative: "Patient/SavePatientRelation",
    // getRelatives: "Patient/GetPatientRelation",
    // insertAddress: "Patient/SavePatientAddress",
  },
  Home: {
    GetCountryList: "Home/GetCountryList",
    GetLanguageList: "Home/GetLanguageList",
    SaveAlerts: "Home/SaveAlerts",
    GetAlerts: "Home/GetAlerts",
    addOrRemoveTofavMedicine: "Home/AddRemoveFavouriteMedicine",
    SaveFavouriteMedicine: "Home/SaveFavouriteMedicine",
    GetFavouriteMedicine: "Home/GetFavouriteMedicine",
    SaveSpecialistType: "Home/SaveSpecialistType",
    GetSpecialistTypeById: "Home/GetSpecialistTypeById",
    GetAllSpecialistType: "Home/GetAllSpecialistType",
    getNotifications: "Home/GetNotifications",
    updateRedStatus: "Home/UpdateRedNotifications",
    getAuditLog: "Home/GetAuditLog"
  },
  appointment: {
    save: "Appointment/SaveAppointment",
    savePackageAppointment: "Appointment/SavePackageAppointments",
    saveDetails: "Appointment/SaveAppointmentDetails",
    getDetails: "Appointment/GetAppointmentDetailsById",
    getAllCompletedDetails: "Appointment/GetCompletedAppointmentDetails",
    getAll: "Appointment/GetAll",
    getAllNew: "Appointment/GetAllNew",
    updateStatus: "Appointment/UpdateStatus",
    updateTimings: "Appointment/UpdateTiming",
    updateConcentStatus: "Appointment/UpdateConsentStatus",
    getByAppointmentId: "Appointment/GetById",
    updatePaymentStatus: "Appointment/UpdatePaymentStatus",
    todaysAppointmentsList: "Appointment/GetAllTodayAppointmentsById",
    dashboardCount: "Appointment/Dashboard",
    assignResouce: "Appointment/AddAppointmentResources",
    assignDriver: "Appointment/AssignDriver",
    updateInOutTimings: "Appointment/UpdateInOutTiming",
    getClinicianSlots: "Appointment/GetPhysicianSlots",
    updateRecurringAppointment: "Appointment/UpdateRecurringAppointment",
    getAvailableClinicinas: "Appointment/GetAvlPhysiciansBetweenGivenSlots",
    getAvailableDrivers: "Appointment/GetAvlDriversBetweenGivenSlots",

    getClinicalPhotography: "Appointment/GetClinicalPhotography",
    saveClinicalPhotography: "Appointment/SaveClinicalPhotography",
    deleteClinicalPhotography: "Appointment/DeleteClinicalPhotography",
    savePaymet: "Appointment/AddAppointmentPayment",
    saveEndorsment: "Appointment/SaveEndorsementFile",
    getEndorsment: "Appointment/GetEndorsementFile",
    cancelAppointment: "/Appointment/CancelAppointments",
    UploadConsultationDocuments: "Appointment/UploadConsultationDocuments",
    DeleteConsultationDocuments: "Appointment/DeleteConsultationDocuments",
    GetConsultationDocuments: "Appointment/GetConsultationDocuments",
    MarkAsCompletedById: "Appointment/MarkAsCompletedById",
    Arabic: "Appointment/TranslationPlainTextToArabic"
    // getAllAppointmentsDetails: "Appointment/GetAppointmentsByPatientId",
    // getById: "Appointment/GetByAppointmentId",
    // getAppointmentByIdWithoutToken: "Appointment/GetByIdWithoutToken",

    // saveAppointment: "Appointment/SaveAppointment",
    // savePackageAppointment: "Appointment/SaveAppointmentForVisits",
    // saveAppointment2: "Appointment/SaveAppointmentNew",
    // getAll: "Appointment/GetAllAppointmentsById",
    // getFollowupAppointments: "Appointment/GetFollowUpAppointments",
    // // getById: "Appointment/GetById",
    // assignBed: "Appointment/AssignBed",
    // updateStatus: "Appointment/UpdateStatus",
    // getByIdPresc: "Appointment/GetByIdPresc",
    // getMedicineForPresc: "Appointment/GetMedicineForPresc",
    // bookAppointment: "Appointment/BookAppointment",
    // saveDischargeSummary: "Appointment/SaveDischargeSummary",
    // saveRefPhysician: "Appointment/SaveAppointmentReferral",
    // getRefAppointments: "Appointment/GetReferralAppointments",
    // getRefPhysician: "Appointment/GetAppointmentReferralById",
    // sendFileToMail: "Appointment/SendAppointmentMailToPatient",
    // saveFeedback: "Appointment/SaveAppointmentFeedback",
    // getFeedback: "Appointment/GetAppointmentFeedback",

    // saveRecurringAppointment: "Appointment/SaveRecurringAppointment",
    // saveClinicalPhotography: "Appointment/SaveClinicalPhotography",
    // getClinicalPhotography: "Appointment/GetClinicalPhotography",
    // saveconcent: "Appointment/AddConcentFormForAppointment",
    // saveRecurringAppointmentWithoutToken: "Appointment/SaveRecurringAppointmentWithoutToken",
    // billing: "Appointment/GetAppointmentView",

    // assignDriver: "Appointment/UpdateAppointmentAmbulanceDriver",
  },

  SupplyRequest: {
    save: "SupplyRequest/Save",
    getAll: "SupplyRequest/GetAll",
    updateStatus: "SupplyRequest/UpdateStatus"
  },
  Offers: {
    save: "Offer/Save",
    getAllOffers: "Offer/GetAll",
    getById: "Offer/GetById",
    addOfferImage: "Offer/AddOfferImage"
  },
  medicine: {
    getAll: "Medicine/GetAll",
    save: "Medicine/Save",
    getMedicineStock: "Medicine/GetMedicineStock",
    updateStatus: "Medicine/UpdateStatus",
    getById: "Medicine/GetById",
    getMedicineSaleInfo: "Medicine/GetMedicineSaleInfo",
  },
  medicineCategory: {
    getAll: "MedicineCategory/GetAll",
    save: "MedicineCategory/Save",
  },
  manufacturer: {
    getAll: "Manufacturer/GetAll",
    save: "Manufacturer/Save",
  },
  labTest: {
    getAll: "LabTest/GetAll",
    save: "LabTest/Save",
  },
  lab: {
    getAll: "Lab/GetAll",
    save: "Lab/Save",
  },
  chat: {
    getAll: "Chat/GetChat",
    save: "Chat/Save",
  },

};

// export const urls = {
//   ICD_AM_10: "ICD_AM_10",
//   get_bot_response: "get_bot_response",
//   organization: {
//     GetAllWithoutToken: "Organization/GetAllWithoutToken",
//   },

//   Nphies: {
//     eligibilityCheck: "HL7Fhir/GetHL7EligibilityReqBen",
//     PAresponce: "HL7Fhir/GetPAResponse",
//   },
//   Avality: {
//     eligibilityCheck: "Availity/GetCoveragebyappointmentId",
//   },
//   Home: {
//     GetCountryList: "Home/GetCountryList",
//     GetLanguageList: "Home/GetLanguageList",
//     SaveAlerts:"Home/SaveAlerts",
//     GetAlerts:"Home/GetAlerts",
//     addOrRemoveTofavMedicine:"Home/AddRemoveFavouriteMedicine",
//     SaveFavouriteMedicine:"Home/SaveFavouriteMedicine",
//     GetFavouriteMedicine:"Home/GetFavouriteMedicine",
//     SaveSpecialistType:"Home/SaveSpecialistType",
//     GetSpecialistTypeById: "Home/GetSpecialistTypeById",
//     GetAllSpecialistType:"Home/GetAllSpecialistType",
//     getNotifications: "Home/GetNotifications",
//     updateRedStatus: "Home/UpdateRedNotifications"
//   },
//   auth: {
//     login: "Authentication/Login",
//     updatePassword: "Authentication/UpdatePassword",
//     forgotPassword: "Authentication/SendForgotPasswordMail",
//     createPassword: "Authentication/SetPassword",
//   },
//   chat:{
//     getAll:"Chat/GetChat",
//     save:"Chat/Save"
//   },
//   hospital: {
//     getAll: "Hospital/GetAll",
//     getAllHospitals: "Hospital/GetAllHospitals",
//     save: "Hospital/Save",
//     saveSpecialities: "Hospital/SaveSpecialities",
//     getSpecialities: "Hospital/GetSpecialities",
//   },
//   speciality: {
//     GetAll: "Speciality/GetAll",
//     Save: "Speciality/Save",
//     getAllWithoutToken:"Speciality/GetAllWithoutToken"
//   },
//   ward: {
//     GetAll: "Ward/GetAll",
//     Save: "Ward/Save",
//     UpdateStatus: "Ward/UpdateStatus",
//   },
//   bed: {
//     GetAll: "Bed/GetAll",
//     Save: "Bed/Save",
//     UpdateStatus: "Bed/UpdateStatus",
//   },
//   room: {
//     GetAll: "Room/GetAll",
//     Save: "Room/Save",
//     UpdateStatus: "Room/UpdateStatus",
//   },
//   // home: {
//   //   getNotifications: "Home/GetNotifications",
//   //   updateRedStatus: "Home/UpdateRedNotifications",
//   // },
//   patient: {
//     patient: "Patient/InsertPatient",
//     GetAllPatientinfo: "Patient/GetAllPatientinfo",
//     getAllInsurance: "Patient/GetAllInsuranceCompany",
//     getInsuranceContract: "Patient/GetAllInsuranceContract",
//     getById: "Patient/GetAllPatientinfo",
//     savePatientEducation: "Patient/SavePatientEducation",
//     getPatientEducation: "Patient/GetPatientEducation",
//     savePatientVitals: "Patient/SavePatientVitals",
//     getPatientVitals: "Patient/GetPatientVitals",
//     getAllDetailsForLongitudal:"Patient/GetPatientDetailsById",
//     saveRelative:"Patient/SavePatientRelation",
//     getRelatives:"Patient/GetPatientRelation",
//     insertAddress:"Patient/SavePatientAddress"
//   },
//   PatientFormPack: {
//     save: "PatientFormPack/Save",
//     getAll: "PatientFormPack/GetAll",
//     // "GetById": "FormPack/GetById",
//     // "GetAllForms": "FormPack/GetAllForms",
//     // "getForms": "FormPack/GetFormsByChartPackId",
//   },
//   user: {
//     save: "User/Save",
//     getAllPatients: "User/GetAllPatient",
//     getAllPhysicians: "User/GetAllPhysician",
//     patientGetById: "User/GetPatientById",
//     savePatient: "User/PatientSave",
//     getCollectionAgent: "User/GetCollectionAgent",
//     getLabTechnicians: "User/GetLabTechnicians",
//     getDetailsById: "User/GetDetailsById",
//     getPhysicianDetails: "User/GetPhysicianDetails",
//     getAllHospitalAdmin: "User/GetAllHospitalAdmin",
//     addProfile: "User/AddProfilePhoto",
//     uploadDoc: "User/UploadWhatsappDocs",
//     getProfile: "User/GetUserProfile",
//     updateProfile: "User/UpdateUserProfile",
//     getResources: "User/GetResources",
//     getById:"User/getById",
//     getEnquiryUser:"User/GetEnquiryUser",
//     saveEnquiryUser:"User/SaveEnquiryUser"
//   },
//   physician: {
//     saveAvailability: "Physician/SetAvailability",
//     getAvailability: "Physician/GetAvailability",
//     getAvailabilitywithoutToken: "Physician/GetAvailabilitywithoutToken",
//     getById: "Physician/GetById",
//     getAvailablePhysician: "Physician/GetAvailablePhysicianFromToday",
//     savePhysicianIndent: "Physician/SavePhysicianIndent",
//     getPhysicianIndentById: "Physician/GetPhysicianIndentById",
//     getAllPhysicianIndent: "Physician/GetPhysicianIndent",
//     UpdatePhysicianIndentStatus: "Physician/UpdatePhysicianIndentStatus",
//     UpdatePhysicianIndent: "Physician/UpdatePhysicianIndent",
//     GetPhysicianIndentTran: "Physician/GetPhysicianIndentTran",
//   },
//   lab: {
//     getAll: "Lab/GetAll",
//     save: "Lab/Save",
//   },
//   labDepartment: {
//     getAll: "LabDepartment/GetAll",
//     save: "LabDepartment/Save",
//   },
//   onBoarding: {
//     getAllById: "OnBoardingForms/GetAllByUserId",
//     save: "OnBoardingForms/Save",
//     saveUploads: "OnBoardingForms/SaveUploads",
//   },
//   labTest: {
//     getAll: "LabTest/GetAll",
//     save: "LabTest/Save",
//   },
//   labTestGroup: {
//     getAll: "LabTestGroup/GetAll",
//     save: "LabTestGroup/Save",
//     getLabTests: "LabTestGroup/GetLabTests",
//   },
//   labPackage: {
//     getAll: "LabPackage/GetAll",
//     save: "LabPackage/Save",
//   },
//   labAppointment: {
//     getAll: "LabAppointment/GetAll",
//     save: "LabAppointment/Save",
//     getById: "LabAppointment/GetById",
//     updateStatus: "LabAppointment/UpdateStatus",
//     collection: "LabAppointment/UpdateCollectionAgentDetails",
//     getLabTestsForTechnician: "LabAppointment/GetLabTestsForTechnician",
//     getLab: "LabAppointment/GetLab",
//     updateLabAppointmentByTechnician: "LabAppointment/UpdateLabAppointmentByTechnician",
//     getAllFilteredLabTests: "LabAppointment/GetAllFilteredLabTests",
//     getLabAppointmentBillData: "LabAppointment/GetLabAppointmentBillData",
//     getLabAppointmentReport: "LabAppointment/GetLabAppointmentReport",
//     uploadLabFile: "LabAppointment/SaveLabFiles",
//     saveLabFiles: "LabAppointment/SaveLabUploads",
//     getLabFiles: "LabAppointment/GetLabUploads",
//     bookLabAppointment: "LabAppointment/BookLabAppointment",
//   },
//   medicineCategory: {
//     getAll: "MedicineCategory/GetAll",
//     save: "MedicineCategory/Save",
//   },
//   manufacturer: {
//     getAll: "Manufacturer/GetAll",
//     save: "Manufacturer/Save",
//   },
//   medicine: {
//     getAll: "Medicine/GetAll",
//     save: "Medicine/Save",
//     getMedicineStock: "Medicine/GetMedicineStock",
//     updateStatus: "Medicine/UpdateStatus",
//     getById: "Medicine/GetById",
//     getMedicineSaleInfo: "Medicine/GetMedicineSaleInfo",
//   },

//   medicineSale: {
//     getMedicineSaleInfo: "MedicineSale/GetMedicineSaleInfo",
//     save: "MedicineSale/Save",
//     getAll: "MedicineSale/GetAll",
//     getById: "MedicineSale/GetById",
//     getMedicineBillReport: "MedicineSale/GetMedicineBillReport",
//   },
//   medicinePurchase: {
//     save: "MedicinePurchase/Save",
//     getAll: "MedicinePurchase/GetAll",
//     getById: "MedicinePurchase/GetById",
//   },
//   vendor: {
//     getAll: "Vendor/GetAll",
//     updateStatus: "Vendor/UpdateStatus",
//     save: "Vendor/Save",
//   },
//   appointment: {
//     getAllAppointmentsDetails:"Appointment/GetAppointmentsByPatientId",
//     getById: "Appointment/GetByAppointmentId",
//     getAppointmentByIdWithoutToken: "Appointment/GetByIdWithoutToken",
//     getByAppointmentId: "Appointment/GetById",
//     saveAppointment: "Appointment/SaveAppointment",
//     savePackageAppointment:"Appointment/SaveAppointmentForVisits",
//     saveAppointment2:"Appointment/SaveAppointmentNew",
//     getAll: "Appointment/GetAllAppointmentsById",
//     getFollowupAppointments: "Appointment/GetFollowUpAppointments",
//     // getById: "Appointment/GetById",
//     assignBed: "Appointment/AssignBed",
//     updateStatus: "Appointment/UpdateStatus",
//     getByIdPresc: "Appointment/GetByIdPresc",
//     getMedicineForPresc: "Appointment/GetMedicineForPresc",
//     bookAppointment: "Appointment/BookAppointment",
//     saveDischargeSummary: "Appointment/SaveDischargeSummary",
//     saveRefPhysician: "Appointment/SaveAppointmentReferral",
//     getRefAppointments: "Appointment/GetReferralAppointments",
//     getRefPhysician: "Appointment/GetAppointmentReferralById",
//     sendFileToMail: "Appointment/SendAppointmentMailToPatient",
//     saveFeedback: "Appointment/SaveAppointmentFeedback",
//     getFeedback: "Appointment/GetAppointmentFeedback",
//     dashboardCount: "Appointment/Dashboard",
//     todaysAppointmentsList: "Appointment/GetAllTodayAppointmentsById",
//     saveRecurringAppointment: "Appointment/SaveRecurringAppointment",
//     updateRecurringAppointment: "Appointment/UpdateRecurringAppointment",
//     saveClinicalPhotography: "Appointment/SaveClinicalPhotography",
//     getClinicalPhotography: "Appointment/GetClinicalPhotography",
//     saveconcent:"Appointment/AddConcentFormForAppointment",
//     saveRecurringAppointmentWithoutToken:"Appointment/SaveRecurringAppointmentWithoutToken",
//     billing:"Appointment/GetAppointmentView",
//     updatePaymentStatus:"Appointment/UpdatePaymentStatus",
//     assignDriver:"Appointment/UpdateAppointmentAmbulanceDriver"
//   },
//   package:{
//     save:"Appointment/SaveAppointmentPackage",
//     getAll:"Appointment/GetAppointmentPackage",
//     getAllWithoutToken:"Appointment/GetAppointmentPackageWithoutToken"
//   },
//   formPack: {
//     getAll: "FormPack/GetAll",
//     save: "FormPack/Save",
//     getAllForms: "FormPack/GetAllForms",
//     getFormsById: "FormPack/GetFormsByFormPackId",
//   },
//   bag: {
//     getAll: "Bag/GetAll",
//     getById: "Bag/GetById",
//     updateStatus: "Bag/UpdateStatus",
//     save: "Bag/Save",
//     sequenceNumber: "Bag/SequenceNumber",
//     saveStatus: "Bag/SaveStatus",
//   },
//   cbwtf: {
//     getAll: "Cbwtf/GetAll",
//     getById: "Cbwtf/GetById",
//     updateStatus: "Cbwtf/UpdateStatus",
//     save: "Cbwtf/Save",
//   },
//   unit: {
//     getAll: "Unit/GetAll",
//     save: "Unit/Save",
//   },
//   itemCategory: {
//     getAll: "ItemCategory/GetAll ",
//     save: "ItemCategory/Save",
//   },
//   itemMaster: {
//     getAll: "ItemMaster/GetAll",
//     save: "ItemMaster/Save",
//   },
//   department: {
//     getAll: "Department/GetAll",
//     save: "Department/Save",
//   },
//   purchase: {
//     getAll: "Purchase/GetAll",
//     save: "Purchase/Save",
//     saveStatus: "Purchase/SaveStatus",
//     getById: "Purchase/GetById",
//   },
//   purchaseDetail: {
//     getById: "PurchaseDetail/GetById",
//     getAll: "PurchaseDetail/GetAllByPurchaseId",
//     save: "PurchaseDetail/Save",
//     create: "PurchaseDetail/Create",
//   },
//   inDent: {
//     getAll: "InDent/GetAll",
//     save: "InDent/Save",
//     saveStatus: "InDent/SaveStatus",
//     getById: "InDent/GetById",
//   },
//   inDentDetail: {
//     getById: "InDentDetail/GetById",
//     getAll: "InDentDetail/GetAllByInDentId",
//     save: "InDentDetail/Save",
//     create: "InDentDetail/Create",
//   },

//   employee: {
//     saveStatus: "Employee/SaveStatus",
//     getById: "Employee/GetById",
//     getAll: "Employee/GetAll",
//     save: "Employee/Save",
//     create: "Employee/Create",
//   },
//   designation: {
//     getAll: "Designation/GetAll",
//     save: "Designation/Save",
//   },
//   role: {
//     getAll: "Role/GetRolesForTask",
//   },
//   workShift: {
//     //"SaveStatus": "WorkShift/SaveStatus",
//     getById: "WorkShift/GetById",
//     getAll: "WorkShift/GetAll",
//     save: "WorkShift/Save",
//     // "Create": "WorkShift/Create",
//     // "GetDetailsById": "WorkShift/GetById"
//   },
//   employeePayment: {
//     saveStatus: "EmployeePayment/SaveStatus",
//     getById: "EmployeePayment/GetById",
//     getAll: "EmployeePayment/GetAll",
//     save: "EmployeePayment/Save",
//     create: "EmployeePayment/Create",
//     getDetailsById: "EmployeePayment/GetById",
//   },
//   employeeWorkShift: {
//     //"SaveStatus": "WorkShift/SaveStatus",
//     getById: "EmployeeWorkShift/GetById",
//     getAll: "EmployeeWorkShift/GetAll",
//     save: "EmployeeWorkShift/Save",
//     // "Create": "WorkShift/Create",
//     // "GetDetailsById": "WorkShift/GetById"
//   },
//   cred: {
//     physicianAndNonphysician: "OnBoardingForms/GetOnBoardingForms",
//     saveForm: "OnBoardingForms/Save",
//     getAll: "OnBoardingForms/GetAllByUserId",
//     getbyId: "OnBoardingForms/GetById",
//     status: "OnBoardingForms/UpdateFormStatus",
//     termsAndConditions: "User/GetTermsConditions",
//     termsStatus: "User/UpdateTermsCondStatus",
//     credApprove: "User/UpdateCredStatus",
//     dashboard: "OnBoardingForms/credentialing",
//     physicianDashboard: "OnBoardingForms/CredPhysicianCountForDashboard",
//     dashboardById: "OnBoardingForms/CredGetByUserId",
//     saveUploads: "OnBoardingForms/SaveUploads",
//     updateDate: "OnBoardingForms/UpdateCredCertificateInfo",
//   },
//   billMaster: {
//     Save: "BillMaster/Save",
//     getById: "BillMaster/GetById",
//     updateStatus: "BillMaster/UpdateBillingStatus",
//     getAll: "BillMaster/GetBillDetailsForClaim",
//     getAllModifiers: "BillMaster/GetAllModifiers",
//     getChargeById: "BillMaster/GetChargeAmountByChargeCode",
//     getRevenueCodes: "BillMaster/GetAllRevenueCodes",
//     getEncounterTypes: "BillMaster/GetAllEncounterType",
//     getAmountByChargeCode: "BillMaster/GetChargeAmountByChargeCode",
//     getAmountByModifier: "BillMaster/GetChargeAmountByModifier",
//   },
//   bot: {
//     bot: "bot_response",
//     icd10: "get_icd_codes",
//     procedure: "get_procedure_codes",
//     diagnosis: "get_diagnosis_codes",
//     cpt: "get_cpt_codes",
//   },

//   Ambulance: {
//     Save: "Ambulance/Save",
//     GetAll: "Ambulance/GetAll",
//     GetById: "Ambulance/GetById",
//     SaveUploads: "Ambulance/SaveUploads",
//   },
//   driver: {
//     saveDiver: "Driver/Save",
//     getAllDrivers: "Driver/GetAll",
//   },
//   service: {
//     save: "Service/Save",
//     getById: "Service/GetById",
//     getAll: "Service/GetAll",
//     getAllWithoutToken:"Service/GetAllWithoutToken"
//   },
//   offer: {
//     save: "Offer/Save",
//     getAll: "Offer/GetAll",
//     getById: "Offer/GetById",
//   },
//   zone:{
//     save:"Zone/Save",
//     getAll:"Zone/GetAll"
//   },
//   pincode:{
//     save:"PinCode/Save",
//     getAll:"PinCode/GetAll"
//   }

// };
