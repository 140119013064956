import moment from "moment";

const initalState = {
  language: "English",
  portfolioId: 0,
  status: "All",
  fromDate: moment()?.subtract(14, "days"),
  toDate: moment()?.add(14, "days"),
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "CHANGESTATE":
      return {
        ...state,
        ...action?.payload,
      };
    default:
      return state;
  }
};

export { reducer };